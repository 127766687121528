import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Amplify, { Storage } from "aws-amplify";
import AuthContext from "../../store/AuthContext";
import HelmetMetaData from "../HelmetMetaData";
import Paragraphs from "./Paragraphs";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./singlePost.css";

export default function SinglePost({ post }) {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [image, setImage] = useState([]);
  const [words, setWords] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const numberOfWords = () => {
    let paragraph = post.desc.trim();
    for (let i = 0; i < paragraph.length; i++) {
      if (paragraph[i] === " ")
        setWords((prevState) => {
          return prevState + 1;
        });
    }
  };

  useEffect(numberOfWords, [post.desc]);

  useEffect(() => {
    Amplify.configure({
      Auth: {
        identityPoolId: "ap-south-1:cb6c9137-6fd5-4532-a7ff-5aedc94d87f3",
        region: "ap-south-1",
      },
      Storage: {
        AWSS3: {
          bucket: "sankalpvridhi",
          region: "ap-south-1",
        },
      },
    });
  }, []);

  useEffect(() => {
    if (post.photo.constructor === Array) {
      for (let i = 0; i < post.photo.length; i++) {
        const imagePath = post.photo[i].split("/")[1];
        Storage.get(imagePath)
          .then((res) => {
            setImage((prevState) => {
              return [...prevState, res];
            });
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      const imagePath = post.photo.split("/")[1];
      Storage.get(imagePath)
        .then((res) => {
          setImage(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [post]);

  const deletePostHandler = async (id) => {
    await fetch(`https://sankalpvridhibackend.herokuapp.com/api/posts/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({
        username: authCtx.user.username,
      }),
    });
    history.replace("/blogs");
  };

  return (
    <SkeletonTheme
      width="50%"
      height="30rem"
      baseColor="#202020"
      highlightColor="#fff"
    >
      <div className="singlePost">
        <HelmetMetaData
          quote="SankalpVridhi-Help the children now, help the country in future"
          title={post.title}
          image={image[0]}
          description={post.desc.substr(0, 100)}
          hashtag="#sankalpvridhi"
          keywords={post.keyWords || ""}
        ></HelmetMetaData>
        <div className="singlePostWrapper">
          {!isLoading ? (
            <img
              className="singlePostImg"
              src={post.photo.constructor === Array ? image[0] : image}
              alt="Sankalp-vridhii-non-profit-ngo-children-village-education"
            />
          ) : (
            <Skeleton />
          )}

          <h1 className="singlePostTitle">
            {" "}
            {post.title}
            {authCtx.user.username === "sankalpvridhiofficial" ? (
              <div className="singlePostEdit">
                <Link to={`/write/${post._id}?edit=true`}>
                  <i className="singlePostIcon far fa-edit"></i>
                </Link>

                <i
                  className="singlePostIcon far fa-trash-alt"
                  onClick={deletePostHandler.bind(null, post._id)}
                ></i>
              </div>
            ) : (
              ""
            )}
            <div className="social-media-container">
              <FacebookShareButton
                url={"https://sankalpvridhi.com"}
                quote={
                  "SankalpVridhi-Help the children now, help the country in future"
                }
                hashtag="#sankalpvridhi"
              >
                <FacebookIcon size={36} />
              </FacebookShareButton>
              <WhatsappShareButton
                url={"https://sankalpvridhi.com"}
                title={
                  "SankalpVridhi-Help the children now, help the country in future"
                }
                separator=":: "
              >
                <WhatsappIcon size={36} />
              </WhatsappShareButton>
            </div>
          </h1>
          <hr className="roll" />

          <div className="singlePostInfo">
            <span className="singlePostAuthor">
              {" "}
              Author :: <b> {post.username} </b>
            </span>
            <span className="singlePostDate">
              {" "}
              {post.createdAt.substr(0, 10)}{" "}
            </span>
          </div>
          <div className="singlePostDesc">
            <Paragraphs offset={0} limit={words / 3} desc={post.desc} />
          </div>
          {post.photo.constructor === Array && image[1] && (
            <img
              className="singlePostImg"
              src={image[1]}
              alt="Sankalp-vridhii-non-profit-ngo-children-village-education"
            />
          )}
          <div className="subPostDesc">
            <Paragraphs
              offset={words / 3}
              limit={2 * (words / 3)}
              desc={post.desc}
            />
          </div>
          {post.photo.constructor === Array && image[2] && (
            <img
              className="singlePostImg"
              src={image[2]}
              alt="Sankalp-vridhii-non-profit-ngo-children-village-education"
            />
          )}
          <div className="subPostDesc">
            <Paragraphs
              offset={2 * (words / 3)}
              limit={words}
              desc={post.desc}
            />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}
