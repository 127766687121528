import React, { useContext, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import SimpleReactLightbox from "simple-react-lightbox";
import Navbar from "./components/navbar/Navbar";
import AuthProvider from "./store/AuthProvider";
import AuthContext from "./store/AuthContext";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Home from "./pages/home/Home";
import BlogHome from "./pages/home/BlogHome.jsx";
import Single from "./pages/blogs/Single/Single";
import Write from "./pages/blogs/Write/Write";
import Settings from "./pages/blogs/Settings/Settings";
import Login from "./pages/blogs/Login/Login";
import Register from "./pages/blogs/Register/Register";
import { Redirect, Route, Switch } from "react-router-dom";
const GetInvolved = React.lazy(() => import("./pages/GetInvolved"));
const OurCampaign = React.lazy(() => import("./pages/OurCampaign"));
const Donation = React.lazy(() => import("./pages/Donation"));
const About = React.lazy(() => import("./pages/about/About"));
const Campaignn = React.lazy(() => import("./components/skcard/Campaign"));
const Contact = React.lazy(() => import("./pages/contact/Contact"));
const Gallery = React.lazy(() => import("./pages/gallery/Gallery"));

function App() {
  const authCtx = useContext(AuthContext);
  return (
    <div className="App" style={{ boxSizing: "border-box" }}>
      <HelmetProvider>
        <SimpleReactLightbox>
          <AuthProvider>
            <Suspense fallback={<p>Loading...</p>}>
              <Navbar />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/ourcampaign" component={OurCampaign} />
                <Route exact path="/getinvolved" component={GetInvolved} />
                <Route exact path="/donation" component={Donation} />
                <Route exact path="/campaign" component={Campaignn} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/gallery" component={Gallery} />
                {/* <Route exact path="/blogs">
                  <BlogHome />
                </Route> */}
                <Route exact path="/register">
                  {Object.keys(authCtx.user).length !== 0 ? (
                    <BlogHome />
                  ) : (
                    <Register />
                  )}
                </Route>
                <Route exact path="/login">
                  {Object.keys(authCtx.user).length !== 0 ? (
                    <BlogHome />
                  ) : (
                    <Login />
                  )}
                </Route>
                <Route exact path="/write/:postId">
                  {authCtx.user ? <Write /> : <Login />}
                </Route>
                <Route exact path="/write">
                  {authCtx.user ? <Write /> : <Login />}
                </Route>
                <Route exact path="/settings">
                  {authCtx.user ? <Settings /> : <Register />}
                </Route>
                <Route exact path="/post/:postId">
                  <Single />
                </Route>
                <Redirect to="/" />
              </Switch>
            </Suspense>
          </AuthProvider>
        </SimpleReactLightbox>
      </HelmetProvider>
    </div>
  );
}

export default App;
