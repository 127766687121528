import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import AuthContext from "../../../store/AuthContext";
import Loader from "../../../components/loaders/Loader";
import "./write.css";

export default function Write() {
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const [file, setFile] = useState([]);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [keywords, setKeyWords] = useState("");
  const [image, setImage] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const postId = useParams().postId;
  const url = useLocation().search;

  // eslint-disable-next-line
  useEffect(async () => {
    const queryParams = new URLSearchParams(url);
    for (const p of queryParams) {
      if (p[1]) {
        setIsEditing(true);
      }
    }
    if (isEditing) {
      try {
        const res = await fetch(
          `https://sankalpvridhibackend.herokuapp.com/api/posts/${postId}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        const data = await res.json();
        setName(data.title);
        setDesc(data.desc);
        setImage(data.photo);
        if (data.keyWords && data.keyWords.length > 0) {
          setKeyWords(data.keyWords);
        }
      } catch {
        console.log("Server Error");
      }
    }
  }, [isEditing, postId, url]);

  const fileChangeHandler = (e) => {
    e.preventDefault();
    let uploadedFiles = e.target.files;
    for (let i = 0; i < uploadedFiles.length; i++) {
      setFile((prevState) => {
        return [...prevState, uploadedFiles[i]];
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const title = e.target.title.value;
    const desc = e.target.desc.value;
    const key = e.target.keywords.value;
    const formData = new FormData();
    let data;
    for (let i = 0; i < file.length; i++) {
      formData.append("files", file[i]);
    }
    if (isEditing && file.length > 0) {
      for (let i = 0; i < image.length; i++) {
        formData.append("oldPath", image[i]);
      }
    }
    try {
      const res = await fetch(
        "https://excited-glasses-pig.cyclic.app/api/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      data = await res.json();
    } catch {
      console.log("Server Error");
    }
    let filePath;
    if (isEditing && data.path.length === 0) {
      filePath = image;
    } else {
      filePath = data.path;
    }
    if (!isEditing) {
      filePath = data.path;
    }
    const url = isEditing
      ? `https://sankalpvridhibackend.herokuapp.com/api/posts/${postId}`
      : "https://sankalpvridhibackend.herokuapp.com/api/posts/";
    const method = isEditing ? "PUT" : "POST";
    const res = await fetch(url, {
      method: method,
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
      body: JSON.stringify({
        title,
        desc,
        filePath,
        username: authCtx.user.username,
        keyWords: key,
      }),
    });
    const postData = await res.json();
    if (postData) {
      setIsLoading(false);
      history.replace("/blogs");
    }
  };

  return (
    <div className="write">
      <form className="writeForm" onSubmit={submitHandler}>
        <div className="writeFormGroup">
          <div className="fuckinDivideThem">
            <label htmlFor="fileInput">
              <i className=" writeIcon fas fa-plus"></i>
            </label>
            <input
              type="file"
              id="fileInput"
              multiple={true}
              onChange={fileChangeHandler}
            />
          </div>
          <div className="fuckinDivideThem">
            <input
              type="text"
              placeholder=" Title"
              className="writeInput"
              autoFocus={true}
              name="title"
              defaultValue={name}
            />
          </div>
          <div className="fuckinDivideThem">
            <input
              type="text"
              placeholder="Keywords"
              className="writeInput"
              autoFocus={true}
              name="keywords"
              defaultValue={keywords}
            />
          </div>
        </div>

        <div className=" writeFormGroup ">
          <textarea
            placeholder=" Tell Your Story  . . . "
            type="text"
            className="writeInput  writeText "
            name="desc"
            defaultValue={desc}
          ></textarea>
        </div>
        <button className="writeSubmit">
          {" "}
          <h3>Publish</h3>
          {isLoading ? (
            <div className="loader">
              <Loader />
            </div>
          ) : null}{" "}
        </button>
      </form>
    </div>
  );
}
