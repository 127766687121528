import React from "react";

const FooterCaption = () => {
  const thoughts = [
    {
      id: 1,
      desc: `“Go out into the world and do good until there is too much good in the world.” – Larry H. Miller`,
    },
    {
      id: 2,
      desc: `“No one has ever become poor from giving.” – Anne Frank`,
    },
    {
      id: 3,
      desc: `“The best way to find yourself, is to lose yourself in the service of others.” – Mahatma Gandhi`,
    },
    {
      id: 4,
      desc: `“Service to others is the rent you pay for your room here on earth.” – Mohammed Ali`,
    },
    {
      id: 5,
      desc: `“Volunteers do not necessarily have the time; they just have the heart.” – Elizabeth Andrew`,
    },
  ];

  const random = Math.floor(Math.random() * (thoughts.length - 1 - 0 + 1) + 0);

  return (
    <div>
      <p>{thoughts[random].desc}</p>
    </div>
  );
};

export default FooterCaption;
