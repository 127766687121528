import "./settings.css";

export default function Settings() {
  return (
    <div className="settings">
      <div className="settingsWrapper" >
        <div className="settingsTitle">
         <span className="settingsUpdateTitle"> Update Your Account </span>
         <span className="settingsDeleteTitle"> Delete Your Account </span>
        </div>
        <form className="settingsForm">
          <label> Profile Picture </label>
          <div className="settingsPP">
           <img src=" https://media.istockphoto.com/photos/relaxed-man-looking-away-outdoors-picture-id1130462891"  alt="  "/>
           <label htmlFor="fileInput"> 
           <i className="settingsPPIcon fas fa-user"></i>
           </label>
           <input type="file" id="fileInput" style={{display : "none"}}  />
          </div>
          <label> UserName </label>
          <input type="text" placeholder=" Suvro " />
          <label> Email </label>
          <input type="email" placeholder=" suvro@gmail.com " />
          <label> Password </label>
          <input type="passowrd" />
          <button className="settingsSubmit">Update</button>
        </form>
      </div>
    </div>
  )
}
