import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import classes from "./about.module.css";
import about from "../../../images/about.jpg";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../../../../node_modules/aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <section id={classes.section}>
      <h1 className="mb-4"> About Us </h1>
      <div className={classes.wrapper}>
        <div className={classes.image}>
          <img src={about} alt="about display" />
        </div>
        <div className={classes.content}>
          <div className={classes["content-inner"]} data-aos="fade-right">
            <p>
              <b style={{ color: "#0adae9" }}>Sankalp Vridhi</b> is a non-profit
              organization dedicated to raise awareness about education and it's
              values. Due to lack of resources, children in rural areas are
              deprived of a decent education. As a result, they face several
              issues, hurdles and do not receive what they deserve:
              <b style={{ color: "#0adae9" }}> "A Proper Childhood."</b> Sankalp
              Vridhi works directly with these children and teach them for a
              brighter future by focusing on high-quality, creativity-based,
              low-cost education.
            </p>
            <button
              className={`btn btn-primary btn-lg ${classes["btn-styled"]}`}
            >
              <Link to="/about">Explore</Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
