import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../store/AuthContext";
import LogOut from './Logout';
import "./navbar.css";

export default function Navbar() {
  const user = false;
  const authCtx = useContext(AuthContext);

  return (
    <div className="top">
      <div className="topLeft">
        <i className="topIcon fab fa-facebook-f"></i>
        <i className="topIcon fab fa-twitter"></i>
        <i className="topIcon fab fa-instagram"></i>
        <i className="topIcon fab fa-linkedin-in"></i>

        <i className="topIcon fas fa-map-marker-alt"></i>
      </div>
      <div className="topCenter">
        <ul className="topList">
          <li className="topListItem">
            <Link className="link" to="/blogs">
              {" "}
              HOME{" "}
            </Link>
          </li>
          <li className="topListItem">
            <Link className="link" to="/write">
              {" "}
              WRITE
            </Link>{" "}
          </li>
          <li className="topListItem">{authCtx.user && <LogOut/>}</li>
        </ul>
      </div>
      <div className="topRight">
        <i className="topSearchIcon fas fa-search"></i>
        {user ? (
          <img
            className="topImg"
            src="https://www.meme-arsenal.com/memes/415440f60b3b8d5ecaff0e60eb8c14eb.jpg"
            alt="ICONPHOTU"
          />
        ) : (
          <ul className="topList">
            <li className="topListItem">
              <Link className="link" to="/login">
                {" "}
                LOGIN{" "}
              </Link>
            </li>
            <li className="topListItem">
              <Link className="link" to="/register">
                {" "}
                REGISTER{" "}
              </Link>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
