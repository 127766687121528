import React from 'react';
import classes from './testimonial-item.module.css';

const TestimonialItem = ({testimonial}) => {
    return (
        <div className={`mt-5 ${classes['testimonial-item-wrapper']} mb-5`}>
            <div className={classes['testimonial-image-wrapper']}>
            <img src={testimonial.img} alt="sankalp-vridhi-ngo-children-education" className={classes['testimonial-image']} />
            </div>
            <h3>{testimonial.name}</h3>
            <p>{testimonial.desc}</p>
        </div>
    )
}

export default TestimonialItem
