import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const HelmetMetaData = (props) => {
  let location = useLocation();
  let currentUrl = `https://sankalpvridhi.com/${location.pathname}`;
  let quote = props.quote !== undefined ? props.quote : "";
  let title = props.title !== undefined ? props.title : "";
  let image = props.image !== undefined ? props.image : "";
  let description = props.description !== undefined ? props.description : "";
  let hashtag = props.hashtag !== undefined ? props.hashtag : "";

  return (
    <Helmet>
      <title>{title}</title>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="sankalpvridhi" />
      <meta property="og:description" content={description} />
      <meta name="keywords" content={props.keywords} />
    </Helmet>
  );
};

export default HelmetMetaData;
