import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../../store/AuthContext";
import "./login.css";

export default function Login() {
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const submitHandler = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (email === "" || password === "") {
      return;
    }
    const res = await fetch(
      "https://excited-glasses-pig.cyclic.app/api/auth/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      }
    );
    if (res) {
      const data = await res.json();
      if (data.token) {
        const expirationTime = new Date(new Date().getTime() + 1 * 1000 * 3600);
        authCtx.tokenChangeHandler(data.token, expirationTime);
        authCtx.userChangeHandler(data.others);
        history.replace("/blogs");
      } else {
        alert("Incorrect creds");
      }
    }
  };

  return (
    <div className="login">
      <span className="loginTitle">LOGIN </span>
      <form className="loginForm" onSubmit={submitHandler}>
        <label>
          {" "}
          <h3>Email</h3>
        </label>
        <input
          type="text"
          className="loginInput"
          placeholder="Enter Email"
          name="email"
        />
        <label>
          {" "}
          <h3>Password</h3>
        </label>
        <input
          type="password"
          className="loginInput"
          placeholder="Enter Password "
          name="password"
        />
        <button className="loginButton">Login</button>
        <button className="loginRegisterButton">
          <Link className="link" to="/register">
            {" "}
            REGISTER{" "}
          </Link>
        </button>
      </form>
    </div>
  );
}
