import React from "react";
import { Link } from "react-router-dom";
import Card from "../../components/UI/Card";
import classes from "./campaign.module.css";

const Campaign = ({ campaign }) => {
  const { title, description, img } = campaign;

  return (
    <Card>
      <div className={classes["img-background"]}>
        <img src={img} className={classes.imageContent} alt="Sankalp-vridhi-ngo-education-children" />
      </div>
      <div className={classes.wrapper}>
        <Link to="/ourcampaign">
          {" "}
          <h4 className={classes.heading}>
            {" "}
            <span>
              <b>{title} </b>{" "}
            </span>
          </h4>
        </Link>
        <p className={classes.content}>{description}</p>
      </div>
    </Card>
  );
};

export default Campaign;
