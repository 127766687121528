import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Amplify, { Storage } from "aws-amplify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./post.css";

export default function Post({ post }) {
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const timeElapsed = new Date().getTime() - new Date(post.createdAt).getTime();
  const timeElapsedInHours = Math.floor(timeElapsed / (1000 * 60 * 60));
  const timeElapsedInDays = Math.floor(timeElapsedInHours / 24);
  const timeElapsedInMonths =
    new Date().getMonth() -
    new Date(post.createdAt).getMonth() +
    12 * (new Date().getFullYear() - new Date(post.createdAt).getFullYear());
  const publishedAt =
    timeElapsedInHours <= 24
      ? `${timeElapsedInHours} hours ago`
      : timeElapsedInDays <= 30
      ? `${timeElapsedInDays} days ago`
      : `${timeElapsedInMonths} months ago`;

  useEffect(() => {
    Amplify.configure({
      Auth: {
        identityPoolId: "ap-south-1:cb6c9137-6fd5-4532-a7ff-5aedc94d87f3",
        region: "ap-south-1",
      },
      Storage: {
        AWSS3: {
          bucket: "sankalpvridhi",
          region: "ap-south-1",
        },
      },
    });
  }, []);

  useEffect(() => {
    if (post.photo.constructor === Array) {
      const imagePath = post.photo[0].split("/")[1];
      Storage.get(imagePath)
        .then((res) => {
          setImage(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const imagePath = post.photo.split("/")[1];
      Storage.get(imagePath)
        .then((res) => {
          setImage(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [post]);

  return (
    <SkeletonTheme
      width="100%"
      height="280px"
      baseColor="#202020"
      highlightColor="#fff"
    >
      <div className="post">
        {!isLoading ? (
          <img
            className="postImg"
            src={image}
            alt="sankalp-vridhi-ngo-children-village-education"
          />
        ) : (
          <Skeleton />
        )}

        <div className="postInfo">
          <span className="postTitle">
            <Link to={`/post/${post._id}`}>{post.title}</Link>
          </span>
          <hr className="roll" />
          <span className="postDate"> {publishedAt} </span>
        </div>
        <p className="postDesc">{post.desc}</p>
      </div>
    </SkeletonTheme>
  );
}
