import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import FooterCaption from './FooterCaption';
import classes from "./footer.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";

const Footer = () => {
  return (
    <section id={classes.footer}>
      <div className={classes.inner}>
        <div className={classes.left}>
          <h4>Sankalp Vridhi</h4>
          <div className={classes.links}>
            <h5>Quick Access</h5>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about"> About </Link>
              </li>
              <li>
                {" "}
                <Link to="/blogs"> Blogs</Link>
              </li>
              <li>
                {" "}
                <Link to="/gallery"> Gallery </Link>
              </li>
              <li>
                {" "}
                <Link to="/donation"> Donate </Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="getinvolved">Get Involved</Link>
              </li>
            </ul>
          </div>
          <div className={classes.contact}>
            <h5>Contact us at:</h5>
            <span className={classes.contactInfo}>
              Mobile:-8825376808/7367815284
            </span>
            <span className={classes.contactInfo}>
              Email:-sankalpvridhiofficial@example.com
            </span>
            <span className={classes.contactInfo}>Whatsapp:-7367815284</span>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.newsletter}>
            <FooterCaption/>
          </div>
          <div className={classes.social}>
            <span className={classes["social-item"]}>
              <a href="https://www.facebook.com/search/top/?q=sankalpVridhi">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </span>
            <span className={classes["social-item"]}>
              <a href="https://www.youtube.com/channel/UC0b83uoWFl-jFohaIyyhN8g">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </span>
            <span className={classes["social-item"]}>
              <a href="https://instagram.com/sankalp_vridhi?utm_medium=copy_link">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </span>
          </div>
          <div className="row">
            <div className="container-fluid">
              <h4 className={classes.copy}>Copyright@SankalpVridhi:2021</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
