import "./register.css";
import { Link, useHistory } from "react-router-dom";

export default function Login() {
  const history = useHistory();
  const submitHandler = async (e) => {
    e.preventDefault();
    const name = e.target.authorName.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (name === "" || email === "" || password === "") {
      return;
    }
    const res = await fetch(
      "https://excited-glasses-pig.cyclic.app/api/auth/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: name,
          email: email,
          password: password,
        }),
      }
    );
    if (res) {
      alert("User added");
      history.replace("/login");
    }
  };

  return (
    <div className="register">
      <span className="registerTitle"> REGISTER </span>
      <form className="registerForm" onSubmit={submitHandler}>
        <label>
          {" "}
          <h3>Author Name</h3>
        </label>
        <input
          type="text"
          className="registerInput"
          placeholder="Enter Author Name . . ."
          name="authorName"
        />

        <label>
          {" "}
          <h3>Email</h3>
        </label>
        <input
          type="text"
          className="registerInput"
          placeholder="Enter Email . . ."
          name="email"
        />

        <label>
          {" "}
          <h3>Password</h3>
        </label>
        <input
          type="password"
          className="loginInput"
          placeholder="Enter Password . . . "
          name="password"
        />

        <button className="registerButton">Register</button>
        <button className="registerLoginButton">
          <Link className="link" to="/login">
            {" "}
            LOGIN{" "}
          </Link>
        </button>
      </form>
    </div>
  );
}
