import React, { useEffect } from "react";
import AOS from "aos";
import classes from "./card.module.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "../../../node_modules/aos/dist/aos.css";
const Card = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 500,
    });
  }, []);

  return (
    <div className={classes.card} data-aos="zoom-in">
      <div className={classes.top}></div>
      <div className={classes.right}></div>
      <div className={classes.bottom}></div>
      <div className={classes.left}></div>
      {props.children}
    </div>
  );
};

export default Card;
