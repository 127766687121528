import React, { useEffect } from "react";
import StatItem from "./StatItem";
import AOS from "aos";
import classes from "./stats.module.css";
import "../../../../node_modules/aos/dist/aos.css";

const Stats = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 500,
    });
  }, []);

  const stats = [
    {
      id: 1,
      title: "No of the villages reached",
      num: 3,
    },
    {
      id: 2,
      title: "No of students",
      num: 200,
    },
    {
      id: 3,
      title: "No of the shelters made",
      num: 3,
    },
    {
      id: 4,
      title: "No of the events organised",
      num: 11,
    },
    {
      id: 5,
      title: "No of the stationary distributed",
      num: 500,
    },
  ];

  return (
    <section id={classes.stats}>
      <h1 className="mt-5">Our Numbers</h1>
      <div className={classes["stats-wrapper"]} data-aos="zoom-in">
        {stats.map((stat) => {
          return <StatItem key={stat.id} stat={stat} />;
        })}
      </div>
    </section>
  );
};

export default Stats;
