import React, { useContext } from "react";
import AuthContext from "../../store/AuthContext";
import "./Logout.css";

const Logout = () => {
  const authCtx = useContext(AuthContext);

  const logOutClickHandler = () => {
    authCtx.logOut();
  };

  return (
    <button onClick={logOutClickHandler} className="logOut-btn">
      Logout
    </button>
  );
};

export default Logout;
