 import c1 from '../images/independenceDay-celebration-village-ngo-sankalp-vridih.webp';
 import c2 from '../images/teachersday-ngo-sankalp-vridhi-children.jpg';
 import c3 from '../images/dustbin-drive-ngo-village-education-children-sankalp-vridhi.webp';
 import c4 from '../images/girl-birthday-village-wish-sankalp-vridhi.webp';
 import c5 from '../images/shelter-ngo-children-education-sankalp-vridhi.webp'
 export const campaigns = [
  {
    id: 1,
    title: "Independence Day celebration ",
    description:
      "The 74th Independence Day celebration marked a major grand event for Sankalp Vridhi and these children ...",
    img: c1,
  },
  {
    id: 2,
    title: "Teacher's Day celebration ",
    description:
      "After seeing our team's dedication towards helping them in studying and building skills for themselves ... ",
    img: c2,
  },
  {
    id: 3,
    title: "Dustbin Drive ",
    description:
      "Our Sankalp Vridhi team saw that the actual need in the village to make it a little more better after providing education is their cleanliness and hygiene ...",
    img:c3,
  },
  {
    id: 4,
    title: "Birthday Celebration ",
    description:
      "Our team tries their best to know the birthdays of the students before hand and so after that on day... ",
    img: c4,
  },
  {
    id: 5,
    title: "Shelter for clasroom",
    description:
      "Firstly Sankalp Vridhi team took their classes under the shade of tree on a cemented platform ,where ...",
    img: c5,
  },
];
