import React from "react";
import CountUp from "react-countup";
import classes from "./statItem.module.css";

const StatItem = ({ countUpRef, stat }) => {
  return (
    <div className={classes.stat}>
      <h4>{stat.title}</h4>
      <CountUp start={0} end={stat.num} duration={10} delay={5}>
        <span ref={countUpRef} />
      </CountUp>
    </div>
  );
};

export default StatItem;
