import React from "react";

const AuthContext = React.createContext({
  token: "",
  user: {},
  logOut: () => {},
  tokenChangeHandler: (token,expirationTime) => {},
  userChangeHandler: (user) => {},
});

export default AuthContext;
