import React, { useState } from "react";
import { campaigns } from "../../assets/campaign";
import Campaign from "./Campaign";
import About from "./about/About";
import Stats from "./stats/Stats";
import Testimonial from "./testimonials/Testimonial";
import Footer from "../../components/UI/Footer";
import styling from "./home.module.css";
// import Carousel from "react-bootstrap/Carousel";
import CarouselCaption from "../../components/carousel/CarouselCaption";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import classes from "../../components/carousel/buttonStyle.module.css";
import styles from "../../components/UI/card.module.css";

function Home() {
  const imgStyle = {
    height: window.innerWidth > 768 ? "90.8vh" : "60vh",
    imagePosition: "left left",
  };

  const [buttonVisible, setButtonVisible] = useState(false);

  const toggleDisplay = () => {
    setButtonVisible(!buttonVisible);
  };

  return (
    <div>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
        onMouseOver={toggleDisplay}
        onMouseOut={toggleDisplay}
        style={{ overflowX: "hidden" }}
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2Fboy-child-children-village-ngo-education-sankalp-vridhi.jpg?alt=media&token=5287647c-50a4-48f0-9493-38338eb4a7a9"
              className="d-block w-100"
              alt="..."
              style={imgStyle}
            />
            <CarouselCaption />
          </div>
          <div className="carousel-item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2Fc3.webp?alt=media&token=241f91ce-a252-4630-bd9a-b08b56b7eb87"
              className="d-block w-100"
              alt="..."
              style={imgStyle}
            />
            <CarouselCaption />
          </div>
          <div className="carousel-item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2Fngo-education-girl-children-sankalp-vridhi.jpg?alt=media&token=749c2c2d-15b7-4431-b3c9-585b070f65a3"
              className="d-block w-100"
              alt="..."
              style={imgStyle}
            />
            <CarouselCaption />
          </div>
          <div className="carousel-item">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2Fgirls-village-education-ngo-sankalp-vridhi.jpg?alt=media&token=093408e1-5237-40cc-a9f5-8f4c1c80cb5b"
              className="d-block w-100"
              alt="..."
              style={imgStyle}
            />
            <CarouselCaption />
          </div>
        </div>

        <button
          className={`carousel-control-prev ${classes.button} ${
            buttonVisible ? classes.show : ""
          }`}
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className={`carousel-control-next ${classes.button} ${
            classes.right
          } ${buttonVisible ? classes.show : ""}`}
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <h1 className="mt-5 mb-5">
        Our <span className={styling.highlight}>Initiatves</span>
      </h1>
      <div className={styles["card-container"]}>
        {campaigns.map((campaign) => {
          return <Campaign key={campaign.id} campaign={campaign} />;
        })}
      </div>
      <About />
      <Stats />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default Home;
