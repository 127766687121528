import React, { useContext } from "react";
import AuthContext from "../../store/AuthContext";
import BlogNavBar from "../navbar/BlogNavbar";
import "./header.css";

export default function Header() {
  const authCtx = useContext(AuthContext);
  const isNav = authCtx.token ? true : false;

  return <React.Fragment>{isNav && <BlogNavBar />}</React.Fragment>;
}
