import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Posts from "../../components/Posts/Posts";
import "./home.css";
import Loader from "../../components/loaders/Loader";
import Footer from "../../components/UI/Footer";
export default function BlogHome() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line
  useEffect(async () => {
    try {
      const res = await fetch(
        "https://excited-glasses-pig.cyclic.app/api/posts/",
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const posts = await res.json();
      if (posts) {
        setIsLoading(false);
        setPosts(posts);
      }
    } catch {
      <p>Server Error</p>;
    }
  }, []);

  return (
    <>
      <div className="home">
        <Header />
        {isLoading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <Posts posts={posts} />
        )}
      </div>
      <Footer />
    </>
  );
}
