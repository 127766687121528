import React, { useState, useEffect } from "react";

const Paragraphs = (props) => {
  const [para, setPara] = useState("");

  const getWords = () => {
    const temp = props.desc.split(" ");
    let paragraph = temp.slice(props.offset, props.limit).join(" ");
    setPara(paragraph);
  };

  useEffect(getWords, [props]);
  return <p>{para}</p>;
};

export default Paragraphs;
