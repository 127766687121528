import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import SinglePost from "../../../components/SinglePost/SinglePost";
import Loader from "../../../components/loaders/Loader";
import "./single.css";

export default function Single() {
  const id = useParams();
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(false);

  const getPostById = useCallback(async () => {
    try {
      const res = await fetch(
        `https://excited-glasses-pig.cyclic.app/api/posts/${id.postId}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const postData = await res.json();
      setPost(() => {
        return postData;
      });
      if (post) {
        setLoading(true);
      }
    } catch {
      console.log("Server Error");
    }
  }, [id, post]);

  useEffect(() => {
    getPostById();
  }, [getPostById]);

  return (
    <div className="single">
      {loading ? (
        <SinglePost post={post} />
      ) : (
        <div className="loader">
          <Loader />
        </div>
      )}
    </div>
  );
}
