import React from "react";
import classes from "./CarouselCaption.module.css";
// import { useHistory } from 'react-router-dom' ;
// const history = useHistory();
import { HashLink } from "react-router-hash-link";

const CaraouselCaption = () => {
  return (
    <div className={classes.container}>
      <h1>
        <strong> Sankalp Vridhi </strong>
      </h1>
      <p className={classes.content}>
        Help the underprivileged <span className={classes.focus}>children</span>
        now,help the country in the
        <span className={classes.focus}>future</span>
      </p>
      <div className={classes.infoContainer}>
        <button className={`${classes.info} ${classes['info-know']}`}><HashLink smooth to='#footer_footer__39TMn'>Know More</HashLink></button>
        <a href="https://sankalpvridhi.com/donation" className={`${classes.info} ${classes['info-donate']}`}>Donate</a>
      </div>
    </div>
  );
};

export default CaraouselCaption;
