import React from "react";
import TestimonialItem from "./TestimonialItem";
import Slider from "react-slick";
import c1 from "../../../images/ayush.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const testimonials = [
    {
      id: 1,
      name: "Shruti Chandra",
      desc: `It's been an absolute pleasure working with the team all these months. With co-operative team members and commendably great collaboration among them, each task becomes easier to carry out. While working towards the joy of our folks at the village, we too have been experiencing the joy, that of giving and sharing what we're already blessed with. Looking forward in the direction`,
      img: "https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2Fgirl1.jpg?alt=media&token=197bbd02-090d-4517-8b3d-dd4fdb5821c1",
    },
    {
      id: 2,
      name: "Richa Gupta",
      desc: `Hello
      I'm Richa Gupta and one of the member of Sankalp Vridhi. This is an NGO which works for the betterment of the society especially for the small children. Being the part of this cause my overall experience was very good. We are very consistent towards our work.
      Rest of the members are also very helpful they clear all our doubts and are very patient.
      I would really request you'll to join this cause. Some or the other way it will also enhance your future and you all are going to have a great experience.`,
      img: "https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2Fpuja.jpg?alt=media&token=2f0bf5a1-17f8-43a6-9b5e-a25e5d18d9d7",
    },
    {
      id: 3,
      name: "Neha Mahato",
      desc: `Been a wholesome experience working with the members, who've been cooperative throughout. Although being caught up in our own busy schedules, taking out time for sankalp vridhi never came as a burden for the joy that I've experienced by doing my bit definitely matters more than the time I've spared. On an overall basis, its been a great journey all these months and would love to be participating activity in future as well.`,
      img: "https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2Fneha.jpg?alt=media&token=c7ab90b5-8421-445a-abbd-d2cc025fd24b",
    },
    {
      id: 4,
      name: "Ayush Kumar Singh",
      desc: `Hello, I'm Ayush Kumar Singh, the founder of Sankalp Vridhi and the main focus of our organization is to provide quality education to the underprivileged children, spread awareness and be the reason for someone's smiles.

      First and foremost, I'd like to thank all the volunteering members who came forward and joined hands with us, to provide the kids - a better and brighter future. 
      
      I'd also like to share my experience of the very first day of teaching at the village. 
      We saw around thirty children sitting around and waiting for us when we reached the village. It was very enthralling to us. Later, we distributed a few notebooks, pens, and pencils among them and their smiles were priceless.
      
      It was precisely on that day that we decided to pursue this work through all possible means and guide them in liberating their true potential.`,
      img: c1,
    },
    {
      id: 5,
      name: "Manish",
      desc: "I have never seen the cons of any rural village . As I have never got a chance to live a rural life but this NGO has made one of my wishes true . An introvert from childhood and have never spoke so frequently and firmly in front of anyone this organization has also help me in these . I have seen in the villages the darker side of the picture where they don’t have anything and also they don’t have any literacy . They need someone help but who is going to listen to them . This NGO has given a new ray of hope  for the children who have no experience to face the cut throat competition in the end . I would say may Sankalp Vridhi flourish more in these upcoming years.",
      img: "https://firebasestorage.googleapis.com/v0/b/sankalpvridhiassets.appspot.com/o/images%2F1.jpg?alt=media&token=2c7994a4-80b9-4af3-85f2-96d0ac8df4e8",
    },
  ];

  return (
    <div className="mt-5 mb-5">
      <h1>Hear our experience</h1>
      <Slider {...settings}>
        {testimonials.map((item) => {
          return <TestimonialItem testimonial={item} key={item.id} />;
        })}
      </Slider>
    </div>
  );
};

export default Testimonial;
